export const langs = [
  { key: "pl", label: "PL" },
  { key: "en", label: "EN" }
];

export const translations = {
  /*en: {
      "ZALOGUJ SIĘ":
          "LOG IN",
      "LUB ZALOGUJ SIĘ PRZEZ":
          " OR LOG IN WITH",
      "LUB":
          "OR",
      "ZAREJESTRUJ SIĘ":
          "SIGN UP",
      "Nie pamiętasz hasła?":
          "Forgot password?",
      "Pomoc":
          "Help",
      "Jak się zalogować":
          "How To Log In",
      "Adres e-mail":
          "E-mail address",
      "Chat/FB":
          "chat/messenger",
      "Polityka cookies":
          "Cookie policy",
      "Logowanie":
          "Log-in",
      "Hasło":
          "Password",
      "Rejestracja":
          "Sign-up",
      "Podaj nr klienta i token, które znajdują się na umowie":
          "Provide us with client number and token from your agreement",
      "Nr klienta":
          "Client number",
      "Token":
          "Token",
      "Podaj hasło do logowania (hasło min. 8 znaków, w tym wielka litera i cyfra)":
          "Set password (required 8 characters, with at least one upper case letter and digit)",
      "Powtórz hasło":
          "Repeat password",
      "Wybierz sposób logowania do eBOK":
          "Choose login method",
      "Mail":
          "E-mail",
      "ZAREJESTRUJ":
          "SIGN-UP",
      "operacja się powiodła, zostałeś poprawnie zarejestrowany, na Twój adres email została wysłana wiadomość z linkiem aktywacyjnym ":
          "...",
      "zostałeś poprawnie zarejestrowany, możesz się zalogować":
          "...",
      "RACHUNKI I PŁATNOŚCI":
          "INVOICE AND PAYMENTS",
      "LISTA POŁĄCZEŃ":
          "BILLING",
      "USŁUGI":
          "SERVICES",
      "MOJE DANE":
          "MY DATA",
      "WYLOGUJ SIĘ":
          "LOGOUT",
      "Nie masz dostępu, lub podałeś nieprawidłowe dane. Spróbuj się zalogować [ERR 403]":
          "Error: 403 - Incorrect username or password!",
      "Nie odnaleziono [ERR 404]":
          "Error: 404 - not found",
      "Błąd serwera. Jeśli ten komunikat się powtórzy skontaktuj się z administratorem [ERR 500]":
          "Error 500 - Internal Server Error",
      "Poprawnie zalogowano!":
          "Log in success!",
      "Poprawnie zalogowano używająć mediów społecznościowych!":
          "You logged in successfully using social media!",
      "Wiadomość z serwera: ":
          "Server info: ",
      "WYKUP DOSTĘP":
          "BUY ACCESS",
      "DALEJ":
          "MORE",
      "Zmień lub dokup usługę":
          "Change or extend your services",
      "Zgłoś awarię":
          "Report a problem",
      "chat ze specjalistą":
          "chat with specialist",
      "telefon":
          "phone",
      "Edycja danych":
          "Edit your data",
      "Preferowany rodzaj faktury":
          "Default invoice type",
      "Zmień":
          "Change",
      "INFOLINIA":
          "CALL CENTER",
      "Komunikaty":
          "News",
      "Twoje płatności":
          "Your payments",
      "Ostatni rachunek":
          "Last bill",
      "Status":
          "Status",
      "Okres rozliczeniowy":
          "Billing period",
      "Nadpłata":
          "Excess payment",
      "Saldo":
          "Balance",
      "OPŁAĆ":
          "PAY",
      "Twoje usługi":
          "Your services",
      "Usługi":
          "Service name",
      "Umowa do":
          "Agreement until"
  }*/
  pl: {
    "LOG IN": "ZALOGUJ SIĘ",
    "OR LOG IN WITH": "LUB ZALOGUJ SIĘ PRZEZ",
    "OR": "LUB",
    "SIGN UP": "ZAREJESTRUJ SIĘ",
    "Forgot password?": "Nie pamiętasz hasła?",
    "Help": "Pomoc",
    "How To Log In": "Jak się zalogować",
    "E-mail address": "Adres e-mail",
    "Login": "Użytkownik",
    "chat/messenger": "Chat/FB",
    "Cookie policy": "Polityka cookies",
    "Log-in": "Logowanie",
    "Reset password": "Resetowanie hasła",
    "CHANGE PASSWORD": "ZMIEŃ HASŁO",
    "Link to change your password will be sent to your email address.": "Na twój adres e-mail zostanie wysłany link umożliwiający zmianę hasła.",
    "Back to login form": "Powrót do ekranu logowania",
    "Password": "Hasło",
    "Sign-up": "Rejestracja",
    "Consultant registration": "Rejestracja konsultanta",
    "Provide us with client number and token from your agreement": "Podaj nr klienta i token, które znajdują się na umowie",
    "Client number": "Nr klienta",
    "Token": "Token",
    "Set password (required 8 characters, with at least one upper case letter and digit)": "Podaj hasło do logowania (hasło min. 8 znaków, w tym wielka litera i cyfra)",
    "Repeat password": "Powtórz hasło",
    "Choose login method": "Wybierz sposób logowania do eBOK",
    "E-mail": "Mail",
    "SIGN-UP": "ZAREJESTRUJ",
    "You have been registered. Token to activate your account was sent to mail provided in form.": "zostałeś poprawnie zarejestrowany, aby aktywować Twoje konto kliknij w link aktywacyjny podany w mailu",
    "You have been registered using social media. Now you can Log In": "Zostałeś poprawnie zarejestrowany z użyciem mediów społecznościowych. Możesz się zalogować.",
    "INVOICE AND PAYMENTS": "RACHUNKI I PŁATNOŚCI",
    "BILLING": "LISTA POŁĄCZEŃ",
    "SERVICES": "USŁUGI",
    "MY DATA": "MOJE DANE",
    "LOGOUT": "WYLOGUJ SIĘ",
    "Error: 403 - Incorrect username or password!": "Nie masz dostępu, lub podałeś nieprawidłowe dane. Spróbuj się zalogować",
    "Error: 404 - not found": "Nie odnaleziono",
    "Error 500 - Internal Server Error": "Błąd serwera. Jeśli ten komunikat się powtórzy skontaktuj się z administratorem",
    "Log in success!": "Poprawnie zalogowano!",
    "Successfully Logged in using social media": "Poprawnie zalogowano używająć mediów społecznościowych!",
    "Server info: ": "Wiadomość z serwera: ",
    "BUY ACCESS": "WYKUP DOSTĘP",
    "MORE": "WIĘCEJ",
    "Change or extend your services": "Zmień lub dokup usługę",
    "Report a problem": "Zgłoś awarię",
    "chat with specialist": "chat ze specjalistą",
    "Phone": "Telefon",
    "Edit your data": "Edycja danych",
    "Default invoice type": "Preferowany rodzaj faktury",
    "Change": "Zmień",
    "CALL CENTER": "INFOLINIA",
    "News": "Komunikaty",
    "Your payments": "Twoje płatności",
    "Last bill": "Ostatni rachunek",
    "Status": "Status",
    "Billing period": "Okres rozliczeniowy",
    "Excess payment": "Nadpłata",
    "Balance": "Saldo",
    "PAY": "OPŁAĆ",
    "Your services": "Twoje usługi",
    "Service name": "Usługi",
    "Agreement until": "Umowa do",
    "Consultant Log-in": "Logowanie Konsultanta",
    "Customer token": "Token Użytkownika",
    "Customer CRM number": "Numer CRM użytkownika",
    "Something went wrong when trying to log in using social media": "Coś poszło nie tak próbując zalogować się z użyciem mediów społecznościowych",
    "Consultant username": "Nazwa użytkownika konsultanta",
    "This field is required": "Pole jest wymagane",
    "Incorrect value": "Niepoprawna wartość",
    "Change password": "Zmiana hasła",
    "New password": "Nowe hasło",
    "Error occurred": "Wystąpił błąd",
    "You can now log in.": "Teraz możesz się zalogować.",
    "GO TO LOGIN PAGE": "PRZEJDŹ DO LOGOWANIA",
    "Message was sent": "Wiadomość została wysłana",
    "A link to change the password has been sent to your email address.": "Na Twój adres e-mail został wysłany link umożliwiający zmiane hasła.",
    "Password has been changed": "Hasło zostało zmienione",
    "Paid": "Opłacona",
    "Not paid": "Nieopłacona",
    "No Data": "Brak danych",
    "Invoice number": "Nr faktury",
    "Document value": "Wartość dokumentu",
    "Issued date": "Data wystawienia",
    "Due date": "Data płatności",
    "Payment status": "Status płatności",
    "Actions": "Akcje",
    "Last Invoice": "Ostatnia faktura",
    "Account No.": "Numer konta",
    "Due Last Invoice": "Do zapłaty za fakturę",
    "Account balance": "Saldo konta",
    "Download document": "Pobierz dokument",
    "Order invoice": "Zamów fakturę",
    "Invoice already ordered": "Faktura zamówiona",
    "MESSAGES": "KOMUNIKATY",
    "The password should be between 8-12 alphanumeric, include 1 number and 1 capital letter": "Hasło powinno składać się z 8-12 alfanumerycznych znaków, zawierać cyfrę i dużą literę",
    "Customer number or token invalid": "Nieprawidłowy numer klienta lub token",
    "The values are not the same": "Wprowadzone wartości nie są takie same",
    "Address already exist": "Adres już istnieje",
    "Service price": "Cena usługi",
    "End date": "Data zakończenia",
    "Installation address": "Adres instalacji",
    "Main services": "Usługi główne",
    "Device model": "Model urządzenia",
    "Device serial number": "Nr seryjny urządzenia",
    "Collapse": "Zwiń",
    "PACKAGE SERVICES": "USŁUGI PAKIETU",
    "HIDE PACKAGE SERVICES": "UKRYJ USŁUGI PAKIETU",
    "Additional services": "Usługi dodatkowe",
    "Start date": "Świadczenie od",
    "The first 3 months": "Pierwsze 3 miesiące",
    "Next months": "Kolejne misiące",
    "Activation": "Aktywacja",
    "Commitment period": "Okres obowiązywania",
    "Remaining": "Pozostało",
    "months": "miesięcy",
    "ACTIVATE": "AKTYWUJ",
    "Payment history": "Historia płatności",
    "paid": "zapłacono",
    "to paid": "do zapłaty",
    "Title of the message": "Tytuł wiadomości",
    "Date": "Data",
    "You have": "Masz",
    "unread messages": "nieprzeczytane wiadomości",
    "This website is using cookies.": "Ta strona używa ciastek.",
    "By using this website you accept that it will be able to save small portions of data on your computer(cookies). More info about it you can find on ": "Używając tej strony wyrażasz zgodę na zapisaywanie małych porcji danych na twoim komputerze(ciastek). Więcej informacji znajdziesz pod adresem ",
    "Accept": "Wyrażam zgodę",
    "Decline": "Nie wyrażam zgody",
    "Last modified: ": "Ostatnio zaktualizowano: ",
    "Search": "Wyszukaj",
    "Page size": "Rekordów",
    "Page": "Strona",
    "of": "z",
    "Show filters": "Pokaż filtry",
    "Hide filters": "Ukryj filtry",
    "Clear": "Wyczyść",
    "Calling number": "Nr inicjujący",
    "Dialed number": "Nr wybrany",
    "Start": "Początek",
    "Start from": "Początek od",
    "Start to": "Początek do",
    "Type": "Typ",
    "Duration": "Długość",
    "Transfer": "Transfer",
    "Gross price": "Cena brutto",
    "Direction": "Sieć",
    "Information": "Informacja",
    "Error": "Błąd",
    "The invoice has been ordered": "Faktura została zamówiona",
    "No messages to display": "Brak komunikatów do wyświetlania",
    "Bill details": "Szczegóły rachunku",
    "Transfer details": "Dane do przelewu",
    "Show transfer details": "Pokaż dane do przelewu",
    "Contact form": "formularz kontaktowy",
    "Activity history": "Historia aktywności",
    "Operation name": "Nazwa operacji",
    "Operation date": "Data operacji",
    "Privacy policy": "Polityka prywatności",
    "Agreement": "Umowa",
    "Services": "Usługi",
    "Commitment to": "Zobowiązanie do",
    "Price": "Cena",
    "Download SVG": "Pobierz SVG",
    "Download PNG": "Pobierz PNG",
    "Download CSV": "Pobierz CSV",
    "Contact data": "Dane kontaktowe",
    "Correspondence Address": "Adres korespondencyjny",
    "Contact methods": "Metody kontaktu",
    "Save": "Zapisz",
    "Cancel": "Anuluj",
    "Edit": "Edytuj",
    "Client data": "Dane klienta",
    "Company name": "Nazwa firmy",
    "Address": "Adres",
    "Invoice type": "Typ faktury",
    "E-Invoice": "E-faktura",
    "Standard Invoice": "Standardowa faktura",
    "Customer consents": "Zgody abonenckie",
    "I agree": "Wyrażam zgodę",
    "I do not agree": "Nie wyrażam zgody",
    "Last quarter": "Ostatni kwartał",
    "Last six months": "Ostatnie półrocze",
    "Last year": "Ostatni rok",
    "Pay with QR code": "Zrób przelew za pomocą kodu QR",
    "Send report": "Wyślij zgłoszenie",
    "Report content": "Treść zgłoszenia",
    "Report title": "Tytuł zgłoszenia",
    "None": "Brak",
    "from": "od",
    "to": "do",
    "Commitment end date undefined": "Koniec zobowiązania nieokreślony",
    "Undefined": "Nieokreślona",
    "Wrong login or password": "Błędny login lub hasło",
    "Report was sent successfully": "Zgłoszenie zostało wysłane",
    "Error occured while sending report": "Wystąpił błąd podczas wysyłania raportu",
    "Enter your e-mail address": "Wpisz adres e-mail",
    "No address": "Brak adresu",
    "Indefinitely": "Bezterminowo",
    "The billing has been ordered": "Billing został zamówiony",
    "Download billing": "Pobierz billing",
    "Order billing": "Zamów billing",
    "Billing already ordered": "Billing zamówiona",
    "Payment due date": "Termin płatności",
    "No active services": "Brak aktywnych usług",
    "You will be taken to the password reset form": "Zostaniesz przeniesiony do formularza resetowania hasła",
    "Confirm": "Potwierdź",
    "Arrears": "Zaległość",
    "Active until": "Aktywny do",
    "Inactive": "Nieaktywny",
    "document value": "wartość dokumentu",
    "Document number": "Nr dokumentu",
    "Document type": "Typ dokumentu",
    "Not applicable": "Nie dotyczy",
    "Invoice": "Faktura",
    "Invoice correction": "Korekta faktury",
    "Debit note": "Nota obciążeniowa",
    "Credit note": "Nota uznaniowa",
    "Interest note": "Nota odsetkowa",
    "Interest note correction": "Korekta noty odsetkowej",
    "No type": "Brak"
  }
};



