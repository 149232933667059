import { Api, BASIC_PORT } from "./Api";
import { CUSTOMER, MESSAGES, PAYMENT } from "../models/ApiModels";
import {
  Bss$CHANGE_CONTACT_DATA,
  Bss$CHANGE_EINVOICE_CONSENT,
  Bss$CONSENTS,
  Bss$CONSENTS_CHANGE,
  Bss$INFO,
  Bss$LAST_SETTLEMENT,
  Bss$REPORT_SEND,
  Bss$SERVICES,
  Cms$ALL,
  Customer$CUSTOMER_API,
  History$HISTORY, Messages$ATTACHMENT_IDENT_PATH_PARAM,
  Messages$COUNT,
  Messages$DASHBOARD,
  Messages$DELETE_BY_MESSAGE_IDENT, Messages$DOWNLOAD_ATTACHMENT,
  Messages$MARK_AS_READ_BY_MESSAGE_IDENT,
  Messages$MESSAGE_IDENT_PATH_PARAM,
  Payment$DOWNLOAD_INVOICE_BILLING_BY_INVOICE_UID,
  Payment$DOWNLOAD_INVOICE_BY_INVOICE_UID,
  Payment$INVOICE_UID_PATH_PARAM,
  Payment$ORDER_INVOICE_BILLING_BY_INVOICE_UID,
  Payment$ORDER_INVOICE_BY_INVOICE_UID,
  Payment$PAYMENTS_RANGE,
  Payment$RANGE_PATH_PARAM,
  Payment$SETTLEMENTS,
  Theme$THEME,
} from "@comfortel/ebok-model/constants";
import { ReplaceInStringHelper } from "../helpers/replace-in-string";

export class CustApi extends Api {
  static apiUrl =
    (window.location.port === BASIC_PORT && (window as any).var.CUSTAPI_URL) ||
    Customer$CUSTOMER_API;

  static theme() {
    return CustApi.get(Theme$THEME);
  }

  static customerInfo() {
    return CustApi.get(CUSTOMER + Bss$INFO);
  }

  static customerConsents() {
    return CustApi.get(CUSTOMER + Bss$CONSENTS);
  }

  static sendConsent(consent: any) {
    return CustApi.post(CUSTOMER + Bss$CONSENTS_CHANGE, consent);
  }

  static sendCustomerInfo(customerInfo: any) {
    return CustApi.post(CUSTOMER + Bss$CHANGE_CONTACT_DATA, customerInfo);
  }

  static changeInvoiceType(consent: any) {
    return CustApi.post(CUSTOMER + Bss$CHANGE_EINVOICE_CONSENT, consent);
  }

  static sendReport(reportData: any) {
    return CustApi.post(CUSTOMER + Bss$REPORT_SEND, reportData);
  }

  static customerLastSettlement() {
    return CustApi.getCanError(CUSTOMER + Bss$LAST_SETTLEMENT);
  }

  static customerServices() {
    return CustApi.get(CUSTOMER + Bss$SERVICES);
  }

  static customerSettlements() {
    return CustApi.get(PAYMENT + Payment$SETTLEMENTS);
  }

  static customerPayments(range: any) {
    const URL = ReplaceInStringHelper.replace(Payment$PAYMENTS_RANGE, {
      [Payment$RANGE_PATH_PARAM]: range,
    });
    return CustApi.get(PAYMENT + URL);
  }

  static orderInvoice(invoiceUid: string) {
    const URL = ReplaceInStringHelper.replace(
      Payment$ORDER_INVOICE_BY_INVOICE_UID,
      {
        [Payment$INVOICE_UID_PATH_PARAM]: invoiceUid,
      },
    );
    return CustApi.get(PAYMENT + URL);
  }

  static downloadInvoice(invoiceUid: string) {
    const URL = ReplaceInStringHelper.replace(
      Payment$DOWNLOAD_INVOICE_BY_INVOICE_UID,
      {
        [Payment$INVOICE_UID_PATH_PARAM]: invoiceUid,
      },
    );
    return CustApi.get(PAYMENT + URL);
  }

  static orderInvoiceBilling(invoiceUid: string) {
    const URL = ReplaceInStringHelper.replace(
      Payment$ORDER_INVOICE_BILLING_BY_INVOICE_UID,
      {
        [Payment$INVOICE_UID_PATH_PARAM]: invoiceUid,
      },
    );

    return CustApi.get(PAYMENT + URL);
  }

  static downloadInvoiceBilling(invoiceUid: string) {
    const URL = ReplaceInStringHelper.replace(
      Payment$DOWNLOAD_INVOICE_BILLING_BY_INVOICE_UID,
      {
        [Payment$INVOICE_UID_PATH_PARAM]: invoiceUid,
      },
    );

    return CustApi.get(PAYMENT + URL);
  }

  static getAllMessages() {
    return CustApi.get(MESSAGES + Cms$ALL);
  }

  static getDashboardMessages() {
    return CustApi.get(MESSAGES + Messages$DASHBOARD);
  }

  static getMessagesCount() {
    return CustApi.get(MESSAGES + Messages$COUNT);
  }

  static markMessageRead(msgId: string) {
    const URL = ReplaceInStringHelper.replace(
      Messages$MARK_AS_READ_BY_MESSAGE_IDENT,
      {
        [Messages$MESSAGE_IDENT_PATH_PARAM]: msgId,
      },
    );
    return CustApi.get(MESSAGES + URL);
  }

  static markMessageDelete(msgId: any) {
    const URL = ReplaceInStringHelper.replace(Messages$DELETE_BY_MESSAGE_IDENT, {
      [Messages$MESSAGE_IDENT_PATH_PARAM]: msgId,
    });

    return CustApi.delete(MESSAGES + URL);
  }

  static downloadAttachment(ident: string) {
    const URL = ReplaceInStringHelper.replace(
      Messages$DOWNLOAD_ATTACHMENT,
      {
        [Messages$ATTACHMENT_IDENT_PATH_PARAM]: ident,
      },
    );
    return CustApi.get(MESSAGES + URL);
  }

  // ! brak użycia. Obecnie billingi tylko przez pobranie PDF
  // static getCallDataRecords(criteria: any) {
  //   if (criteria) {
  //     return CustApi.post(Data$CALL_DATA + Data$RECORDS, criteria);
  //   }
  // }

  static getHistory(criteria: any) {
    return CustApi.post(History$HISTORY, criteria);
  }
}
