import React, {Component} from "react";
import T from "app/i18n/T";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {Button} from "reactstrap";
import Logger from "../Logger";
import packageJson from '../../../package.json';

class AppFooter extends Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            style: {
                contrast: "",
                font: ""
            },
            baseStyle: null
        }
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (prevProps.theme.contrast !== this.props.theme.contrast) {
            this.changeContrast()
        }
        if (prevProps.theme.font !== this.props.theme.font) {
            this.changeFont()
        }
    }

    componentDidMount = () => {
        this.setState({
            baseStyle: this.props.theme.theme.css
        }, () => {
            this.changeContrast()
            this.changeFont()

        });
        Logger.debug(this.props.theme)
    };

    updateStyle = (type: any, value: any) => {
        this.setState( (oldState: any) => ({
            style: {
                ...oldState.style,
                [type]: value
            }
        }), () => this.applyStyles())
    };

    increaseContrast = () => {
        this.props.setContrast(this.props.theme.contrast === 2 ? 0 : this.props.theme.contrast + 1)
    }

    changeContrast = () => {
        switch (this.props.theme.contrast) {
            case 0: {
                this.updateStyle("contrast", "")
                break
            }
            case 1: {
                this.updateStyle("contrast", "html {filter: grayscale(1) invert(100%) ; color:black !important} .btn, button{background-color: black !important} i, fa, fas, far, a.btn, .btn{color: white !important;} .txt-violet-big{color: black !important;} nav span{color: black !important;}");
                break
            }
            case 2: {
                this.updateStyle("contrast", "html {filter: grayscale(1) invert(100%) sepia(1) saturate(10) hue-rotate(-10deg); color:black !important} .btn, button{background-color: black !important} i, fa, fas, far, a.btn, .btn{color: white !important;} .txt-violet-big{color: black !important;} nav span{color: black !important;}");
                break
            }
            default:
                break;
        }
    }

    increaseFont = () => {
        this.props.setFont(this.props.theme.font === 1 ? 0 : this.props.theme.font + 1);
    }

    changeFont = () => {
        if (this.props.theme.font === 0) {
            this.updateStyle("font", "")
        } else {
            this.updateStyle("font", "* { font-size: 101% !important; } .message-table td { font-size: 140% !important; }")
        }

    }

    applyStyles = () => {
        (document as any).getElementById('theme-style').innerHTML = this.state.baseStyle + this.state.style.contrast + this.state.style.font
    };

    render() {
        return <div className="mt-3 mb-2">
            <div className="container">
                <div className="row top-border d-flex align-items-center pt-2">
                    <div
                      className="col col-12 col-md-3 d-flex justify-content-center justify-content-md-start mt-md-0">
                        <img alt="" src="/logoFooter.png" />
                        <span className="version-txt mt-auto">{packageJson.version}</span>
                    </div>
                    <div className="col col-12 col-md-4 d-flex justify-content-center">
                        <NavLink exact to="/cookies">
                            <span className="footer-txt pt-4 pt-md-0">
                            <i className="fas fa-link"/>&nbsp;
                                {T('Cookie policy')}<br/>
                        </span>
                        </NavLink>
                        {/*Ukryta polityka prywatności [SORT5-9131]*/}
                        {/*<NavLink exact to="/privacy">*/}
                        {/*    <span className="footer-txt pt-4 pt-md-0 ml-2">*/}
                        {/*    <i className="fas fa-link"/>&nbsp;*/}
                        {/*        {T('Privacy policy')}<br/>*/}
                        {/*</span>*/}
                        {/*</NavLink>*/}
                    </div>
                    <div className="col col-md-2 text-center">
                        <Button color="link" onClick={this.increaseContrast}>
                            <i className="fas fa-adjust ebok-color"/>
                        </Button>
                        <Button color="link" onClick={this.increaseFont}>
                            <i className="fas fa-text-size ebok-color"/>
                        </Button>
                    </div>
                    <div className="col col-12 col-md-3">
                        <a className="footer-txt pt-4 pt-md-0 d-flex justify-content-center justify-content-md-end"
                           href={this.props.theme.theme.footerRightLink}>{this.props.theme.theme.footerRightText}<br/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    }

}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(AppFooter)
