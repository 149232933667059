import React, { Component } from "react";
import { Button, Card, CardBody, CardFooter, CardText } from "reactstrap";
import "assets/css/message-collapse-fable-fix.css";
import { CustApi } from "app/api/CustApi";
import T from "app/i18n/T";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import { PieSpinner } from "app/app-components/messages/PieSpinner";
import { MessageIcon } from "app/app-components/messages/MessagesIcon";
import Utils from "app/Utils";
import { ICustomerMessageAttachmentDto, ICustomerMessageDto } from "@comfortel/ebok-model";
import parse from "html-react-parser";

interface ViewMessagesProps {
  setMsgCounter: (counter: number) => void;
}

interface ViewMessagesState {
  card: string;
  messages: ICustomerMessageDto[];
  read?: NodeJS.Timeout | null;
}

class ViewMessages extends Component<ViewMessagesProps, ViewMessagesState> {
  constructor(props: any) {
    super(props);
    this.state = {
      card: "",
      messages: [],
    };
  }

  getMessages = () => {
    CustApi.getAllMessages().then(
      (d: any) => {
        CustApi.getMessagesCount().then(
          (r: any) => this.props.setMsgCounter(r.data.count),
          (error: any) => console.error(error),
        );
        this.setState({
          messages: d.data.messages,
        });
      },
      (error: any) => console.error(error),
    );
  };

  componentDidMount() {
    this.getMessages();
  }

  createMessageTimeout(message: ICustomerMessageDto) {
    return message.status === "READ" || this.state.card === null
      ? null
      : setTimeout(() => {
        CustApi.markMessageRead(message.ident).then(
          () => this.getMessages,
          (error: any) => console.error(error),
        );
        this.getMessages();
      }, 5000);
  }

  selectCardAndSetTimeout(message: any) {
    if (this.state.read !== null) {
      clearTimeout(this.state.read);
      this.setState({
        read: null,
      });
    }
    this.setState(
      {
        card: this.state.card === message.ident ? null : message.ident,
      },
      () => {
        this.setState({
          read: this.createMessageTimeout(message),
        });
      },
    );
  }

  componentWillUnmount() {
    if (this.state.read !== null) {
      clearTimeout(this.state.read);
      this.setState({
        read: null,
      });
    }
  }

  printMessageTitle(message: any) {
    return message.status === "NEW" ? (
      <strong>{message.title}</strong>
    ) : (
      <>{message.title}</>
    );
  }

  deleteMessage = (id: any) => {
    CustApi.markMessageDelete(id).then(
      () => {
        this.getMessages();
      },
      (error: any) => console.error(error),
    );
  };

  downloadInvoice = (invUid: any) => {
    return () => {
      CustApi.downloadInvoice(invUid).then(
        (r: any) => {
          Utils.downloadByBlob(
            r.data.filename,
            r.data.content,
            r.data.mediaType,
          );
        },
        (error: any) => console.error(error),
      );
    };
  };

  downloadAttachment = (ident: string) => {
    return () => {
      CustApi.downloadAttachment(ident).then((r: any) => {
          Utils.downloadByBlob(r.data.filename, r.data.content, r.data.mediaType);
        },
        (error: any) => console.error(error));
    };
  };


  printMessages = () => {
    return this.state.messages.map((message: ICustomerMessageDto) => {
      return (
        <React.Fragment key={message.ident}>
          <tr>
            <td className="text-center">
              {this.state.card === message.ident && message.status !== "READ" ? (
                <PieSpinner style={{ backgroundColor: "inherit" }} />
              ) : (
                <MessageIcon messageType={message.type} />
              )}
            </td>
            <td
              className={
                "pointer " +
                (this.state.card === message.ident ? "header-active" : "")
              }
              onClick={() => this.selectCardAndSetTimeout(message)}
            >
              {this.printMessageTitle(message)}
            </td>
            <td className="text-nowrap text-center">
              {Utils.getFormattedDate(message.created)}
            </td>
            <td className="text-nowrap text-center">
              <Button
                outline
                className="btn-outline-primary"
                color="primary"
                size="sm"
                onClick={() => this.deleteMessage(message.ident)}
              >
                <i className="fa fa-trash" />
              </Button>
            </td>
          </tr>
          <tr
            className={
              this.state.card !== null && this.state.card === message.ident
                ? "active message-row"
                : "message-row"
            }
          >
            <td />
            <td colSpan={3}>
              <div>
                <Card className="mt-2">
                  <CardBody className="w-100">
                    <CardText style={{ whiteSpace: "pre-wrap" }} >
                      {parse(message.content)}
                    </CardText>
                  </CardBody>
                  <CardFooter>
                    <div>
                      {message.attachments.map((attachment: ICustomerMessageAttachmentDto, index: number) => {
                        return (
                          <span
                            className="ebok-color font-weight-bold pointer"
                            key={index}
                            onClick={this.downloadAttachment(attachment.ident)}
                          >
                            <i className="far fa-file-download fa-lg px-2 ebok-color" />
                            {attachment.filename}
                          </span>
                        );
                      })}
                    </div>
                  </CardFooter>
                </Card>
              </div>
            </td>
          </tr>
        </React.Fragment>
      );
    });
  };

  render() {
    return (
      <>
        <div className="container bgr-grey pt-3 pb-3">
          <div className="table-responsive bgr-w">
            <table className="table table-hover message-table">
              <caption className="ml-2">
                {T("You have")}{" "}
                <strong>
                  {
                    this.state.messages.filter((message: any) => {
                      return message.status === "NEW";
                    }).length
                  }
                </strong>{" "}
                {T("unread messages")}
              </caption>
              <thead>
              <tr>
                <th className="text-center" />
                <th className="text-center">{T("Title of the message")}</th>
                <th className="text-center">{T("Date")}</th>
                <th className="text-center" />
              </tr>
              </thead>
              <tbody>{this.printMessages()}</tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  mapStorageToProps,
  mapStorageDispatchToProps,
)(ViewMessages);
