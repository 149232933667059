import React, { useEffect, useState } from "react";
import { Carousel, CarouselIndicators, CarouselItem } from "reactstrap";
import "assets/css/carousel.css";
import T from "app/i18n/T";
import { CustApi } from "app/api/CustApi";
import Util from "app/Utils";
import _ from "lodash";
import { ICustomerMessageDto } from "@comfortel/ebok-model";
import parse from "html-react-parser";

const CustomerMsg: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [messages, setMessages] = useState<ICustomerMessageDto[]>([]);

  useEffect(() => {
    CustApi.getDashboardMessages().then(
      (json: any) => {
        setMessages(json.data.messages);
      },
      (error: any) => console.error(error),
    );
  }, []);

  const onExiting = (): void => {
    animating = true;
  };

  const onExited = (): void => {
    animating = false;
  };

  const next = (): void => {
    if (animating) return;
    const nextIndex = activeIndex === messages.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = (): void => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? messages.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number): void => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = messages.map((item: ICustomerMessageDto, i: number) => (
    <CarouselItem onExiting={onExiting} onExited={onExited} key={i}>
      <span className="row">
        <span className="col-8">
          <p className="txt-black-medium">{item.title}</p>
        </span>
        <span className="col-4 text-right">
          <p className="txt-grey-small mb-0">
            {Util.getFormattedDate(item.created)}
          </p>
        </span>
      </span>
      <p className="txt-black-small mb-0 txt-news-short-4">{parse(item.content)}</p>
    </CarouselItem>
  ));

  let animating = false;

  return (
    <div className="bgr-w h-100 px-4 pb-xl-0">
      <div className="pt-3 pb-4 pb-lg-2 pb-xl-2" style={{ minHeight: 200 }}>
        <p className="ebok-color txt-violet-big mb-3">{T("News")}</p>
        {!_.isEmpty(messages) ? (
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            {slides}
          </Carousel>
        ) : (
          <p className="txt-black-small mb-0 txt-news-short-4">
            {T("No messages to display")}
          </p>
        )}
        <CarouselIndicators
          items={messages}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
          className="circle"
        />
      </div>
    </div>
  );
};

export default CustomerMsg;
